import HttpService from './httpService';

class DocumentosApiService extends HttpService {
  async generarJustificanteExamenOrdinario(data) {
    return this.post('generarJustificanteExamenOrdinario', data, { responseType: 'blob' });
  }

  async generarJustificanteExamenExtraordinario(data) {
    return this.post('generarJustificanteExamenExtraordinario', data, { responseType: 'blob' });
  }
}

const documentosApiService = new DocumentosApiService(process.env.REACT_APP_NODE_API);
export default documentosApiService;
